import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import DetailVideo from './DetailVideo';
import DetailPhotoFiction from './DetailPhotoFiction';
import { NoDetail, LoadingText } from '../components/ErrorRetry';

const Detail = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    
    const fetchData = async () => {
      try {
        const response = await axios.get(`/detail/${id}`);
        if (response.data.status === 200) {
          let data = response.data.data;
            const cipherText = CryptoJS.enc.Base64.parse(data.k1); 
            const iv = CryptoJS.enc.Base64.parse(data.v2); 
            // 使用固定密钥进行解密
            const key = CryptoJS.enc.Utf8.parse("aafb2b88c91ac2b0"); // 固定的16字节密钥
            // 解密操作
            const decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherText }, key, { iv: iv });
            let plainText = decrypted.toString(CryptoJS.enc.Utf8);
            plainText = plainText.replace(/[\x00-\x1F\x7F-\x9F\x10]+/g, '').trim();
            const jsonData = JSON.parse(plainText);
            setData(jsonData);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error || !data) {
    console.log(error)
    return (
      <NoDetail message={"找不到任何内容"} />
    );
  }
  const { typename } = data;

  if (['大陆','三级','日韩','欧美','动漫','短片'].includes(typename)) {
    return <DetailVideo data={data} />;
  } else if (["小说",'漫画','色图','图片'].includes(typename)) {
    return <DetailPhotoFiction data={data} />;
  } else {
    return <NoDetail message={"本内容已下架"} />;
  }
};

export default Detail;
